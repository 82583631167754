import React from 'react';
import Hero from '../modules/Hero/Hero';
import NewsFeed from '../modules/NewsFeed/NewsFeed';
import Seo from "../components/seo";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Header from '../modules/Header/Header';
import Footer from '../modules/Footer/Footer';
import { useStaticQuery, graphql } from 'gatsby';


const NewsPage = () => {
  const { wpPage, wp, wpMenu } = useStaticQuery(
    graphql`
      query {
        wp {
          acfOptionsGeneralSettings {
            themeSettings {
              button1 {
                buttonType
                link {
                  target
                  title
                  url
                }
              }
              button2 {
                buttonType
                link {
                  target
                  title
                  url
                }
              }
              largeBottomText
              fieldGroupName
              textUnderLogo
              address
              sitemap {
                label
                links {
                  link {
                    target
                    title
                    url
                  }
                }
                label2
                links2 {
                  link {
                    target
                    title
                    url
                  }
                }
                label3
                links3 {
                  link {
                    target
                    title
                    url
                  }
                }
              }
              trackers {
                script
                noscript
              }
            }
          }
        }
        wpMenu(locations: {eq: GATSBY_FOOTER_MENU}) {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
        wpPage(template: {templateName: {eq: "News"}}) {
          slug
          seo {
            canonical
            cornerstone
            metaKeywords
            metaDesc
            metaRobotsNoindex
            title
            schema {
              raw
            }
          }
          template {
            ... on WpTemplate_News {
              news {
                heroImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const { slug, seo } = wpPage;
  const heroImage = wpPage.template?.news?.heroImage;
  const menuItems = wpMenu?.menuItems ? wpMenu.menuItems : false;
  const { acfOptionsGeneralSettings: { themeSettings: { button1, button2, largeBottomText, fieldGroupName, textUnderLogo, address, sitemap, trackers } } } = wp;

  return (
    <>
    <Header data={{button1, button2}} slug={slug} />
    <Seo seo={seo} trackers={trackers} />
    <Breadcrumbs currentUrl={slug} currentTitle="News &amp; Publications" />
    <Hero heroType="small" heading="News &amp; Publications" image={heroImage} overlap={true} />
    <NewsFeed />
    <Footer data={{largeBottomText, fieldGroupName, textUnderLogo, address, sitemap}} menuItems={menuItems} />
    </>
   );
}

export default NewsPage;